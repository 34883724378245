



















































































import { computed, ref } from '@vue/composition-api';
import { ActionTypes } from '@/store/modules/tools/actions';
import store, { useUserState, useUserActions } from '@/store';
import axios from 'axios';

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    isValid: {
      type: Boolean,
      default: false
    }
  },

  setup(props, ctx) {
    const { user }: any = useUserState(['user']);
    const { fetchUser } = useUserActions(['fetchUser']);
    const email: any = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const verifyEmailDialog = ref(false);
    const emailVerified = ref(!!user.value?.phoneVerified);
    const isSending = ref(false);
    const isVerifying = ref(false);
    const verificationCode = ref('');

    async function sendOTP() {
      try {
        isSending.value = true;
        const data = {
          email: email.value
        };

        const res = await axios.post(`${process.env.VUE_APP_AUTH0}/email/code/send`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (res.data) {
          verifyEmailDialog.value = true;
          if (localStorage.getItem('apollo-token')) {
            await fetchUser(localStorage.getItem('apollo-token'));
          }
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: 'Verification code sent successfully',
            type: 'success',
            isShowSnackbar: true
          });
        }
        isSending.value = false;
      } catch (error) {
        isSending.value = false;
      }
    }

    async function verifyNumber() {
      try {
        isVerifying.value = true;
        const data = {
          email: email.value,
          code: verificationCode.value
        };

        const res = await axios.post(`${process.env.VUE_APP_AUTH0}/email/code/verify`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (res.data) {
          verifyEmailDialog.value = false;
          emailVerified.value = true;
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: 'Email verified successfully',
            type: 'success',
            isShowSnackbar: true
          });
        }
        isVerifying.value = false;
      } catch (error) {
        isVerifying.value = false;
        // console.log(error.response);
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: 'Wrong verification code.',
          type: 'error',
          isShowSnackbar: true
        });
      }
    }

    return {
      email,
      verifyNumber,
      sendOTP,
      verifyEmailDialog,
      isSending,
      emailVerified,
      isVerifying,
      verificationCode
    };
  }
};
